import React from 'react';
import { IconButton } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';

import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import { toggleMockServer } from '../../../store/slices/mockServer.slice';
import { useDarkModeSelected } from '../../../store/slices/theme.slice';

const MockServerToggle: React.FC = () => {
  const isDarkMode = useDarkModeSelected();

  const { active } = useAppSelector((state) => state.mockServer);
  const dispatch = useAppDispatch();

  return (
    <IconButton
      data-testid="toggle-mock-server"
      disableRipple
      onClick={() => dispatch(toggleMockServer())}
    >
      {active ? (
        <WifiOffIcon
          sx={{ color: isDarkMode ? '#ffffff' : '#000' }}
          data-testid="mock-server-active"
        />
      ) : (
        <WifiIcon
          sx={{ color: isDarkMode ? '#ffffff' : '#000' }}
          data-testid="mock-server-inactive"
        />
      )}
    </IconButton>
  );
};

export default MockServerToggle;

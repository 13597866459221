import React from 'react';
import { Typography } from '@mui/material';

import { PredictionQualitySummary } from '../../../integration/predictionQuality.api';
import { FetchingStatus } from '../../../types/common';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import NoDataAvailableCard from '../../cardContainer/NoDataAvailableCard/NoDataAvailableCard';
import { useTranslations } from '../../../store/slices/translation.slice';
import PredictionQualityConfidences from './confidences/PredictionQualityConfidences';
import PredictionQualitySample from './sample/PredictionQualitySample';
import { useAppSelector } from '../../../store/utils/hooks';
import PredictionQualityTrend from '../trend/PredictionQualityTrend';
import styles from './PredictionQualityCard.module.scss';

interface Props {
  predictedTitle: string;
  usedTitle: string;
  predictionQuality: PredictionQualitySummary;
  status: FetchingStatus;
}

const PredictionQualityCard: React.FC<Props> = ({
  predictedTitle,
  usedTitle,
  predictionQuality,
  status,
}) => {
  const timeRange = useAppSelector(
    (state) => state.filters.dashboard.timeRange,
  );
  const { noData } = useTranslations();

  const { timeSpan } = useTranslations();

  const { accuracy, highConfidence, mediumConfidence, lowConfidence, total } =
    predictionQuality;
  // As the UI is distributed in three different sections we are rendering the 'No data message' based on the availability of data.
  const shouldShowNoDataMessage =
    accuracy === 0 ||
    highConfidence === 0 ||
    mediumConfidence === 0 ||
    lowConfidence === 0 ||
    total === 0;

  const noDataMessage =
    shouldShowNoDataMessage &&
    predictionQuality.bestPredictions.length === 0 &&
    predictionQuality.worstPredictions.length === 0;

  return (
    <>
      {status === FetchingStatus.PENDING ? (
        <LoadingOverlay />
      ) : (
        <>
          {noDataMessage ? (
            <NoDataAvailableCard />
          ) : (
            <>
              {shouldShowNoDataMessage ? (
                <Typography
                  textAlign="center"
                  variant="body1"
                  sx={{ marginTop: '16px' }}
                >
                  {noData}
                </Typography>
              ) : (
                <>
                  <Typography variant="h2" className={styles.accuracy}>
                    {predictionQuality.accuracy
                      ? `${Math.floor(predictionQuality.accuracy)}%`
                      : 'N/A'}
                  </Typography>
                  {predictionQuality.trend !== null &&
                    predictionQuality.trend !== 0 && (
                      <Typography variant="body1">
                        <PredictionQualityTrend
                          trend={predictionQuality.trend}
                        />
                        <span className={styles.trendLabel}>
                          {' '}
                          {timeSpan[timeRange]}
                        </span>
                      </Typography>
                    )}
                  <PredictionQualityConfidences
                    predictionQuality={predictionQuality}
                  />
                </>
              )}
              <div className={styles.bestAndWorstPrediction}>
                {predictionQuality.bestPredictions.length === 0 ? (
                  <NoDataAvailableCard />
                ) : (
                  <PredictionQualitySample
                    details={predictionQuality.bestPredictions}
                    predictedTitle={predictedTitle}
                    usedTitle={usedTitle}
                  />
                )}
                {predictionQuality.worstPredictions.length === 0 ? (
                  <NoDataAvailableCard />
                ) : (
                  <PredictionQualitySample
                    details={predictionQuality.worstPredictions}
                    predictedTitle={predictedTitle}
                    usedTitle={usedTitle}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PredictionQualityCard;

import React from 'react';
import { useTheme } from '@mui/material';
import { PieChart as RePieChart, Cell, Label, Pie } from 'recharts';

import { isDefined } from '../../../../../utils/isDefined';

interface Props {
  trueLabelCount: number;
  correctPredictionCount: number;
}

const CorrectPredictionsChart: React.FC<Props> = ({
  trueLabelCount,
  correctPredictionCount,
}) => {
  const theme = useTheme();

  return (
    <RePieChart width={64} height={64}>
      <Pie
        dataKey="count"
        data={[
          {
            id: 'incorrect prediction',
            count: trueLabelCount - correctPredictionCount,
          },
          {
            id: 'total',
            count: correctPredictionCount,
          },
        ]}
        stroke="none"
        innerRadius={29}
        outerRadius={32}
      >
        <Cell fill={theme.palette.error.main} />
        <Cell fill={theme.palette.success.main} />
        <Label
          position="center"
          content={
            <ChartLabel value={`${correctPredictionCount}/${trueLabelCount}`} />
          }
        />
      </Pie>
    </RePieChart>
  );
};

const ChartLabel: React.FC<{
  viewBox?: {
    cx: number;
    cy: number;
  };
  value: string;
}> = ({ viewBox, value }) => {
  const theme = useTheme();
  if (!isDefined(viewBox)) {
    return null;
  }
  const { cx, cy } = viewBox;

  return (
    <text textAnchor="middle">
      <tspan
        x={cx}
        y={cy}
        style={{
          fontSize: '14px',
          fontWeight: 700,
          fill: theme.palette.secondary.light,
        }}
      >
        {value}
      </tspan>
      <tspan
        x={cx}
        y={cy + 10}
        style={{
          fontSize: '12px',
          fontWeight: 400,
          fill: theme.palette.secondary.light,
        }}
      >
        words
      </tspan>
    </text>
  );
};

export default CorrectPredictionsChart;

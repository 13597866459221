import React from 'react';
import { getTrend, getTrendArrow, getTrendColor } from '../../../utils/trend';
import { useTheme } from '@mui/material';

interface Props {
  trend: number;
}

const PredictionQualityTrend: React.FC<Props> = (props) => {
  const theme = useTheme();
  const trendValue = props.trend;
  const trend = getTrend(trendValue);

  return (
    <span style={{ color: getTrendColor(trend, theme) }}>
      {getTrendArrow(trend)} {Math.abs(trendValue * 100).toFixed(1)}%
    </span>
  );
};

export default PredictionQualityTrend;

import React, { useEffect } from 'react';

import PredictionQualityCard from './card/PredictionQualityCard';
import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import { useMockServerActive } from '../../store/slices/mockServer.slice';
import { getRootCausePredictionQuality } from '../../store/slices/predictionQuality.slice';
import { useTranslations } from '../../store/slices/translation.slice';
import { SummaryType } from '../../integration/predictionQuality.api';

const RootCausePredictionQuality: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMockServerActive = useMockServerActive();
  const { rootCausePredictionQuality, rootCausePredictionStatus } =
    useAppSelector((state) => state.predictionQuality);
  const {
    predictionQuality: { rootCauses: translations },
  } = useTranslations();

  useEffect(() => {
    const promise = dispatch(
      getRootCausePredictionQuality(SummaryType.DIAGNOSIS),
    );

    return () => {
      promise.abort();
    };
  }, [isMockServerActive, dispatch]);

  return (
    <PredictionQualityCard
      predictionQuality={rootCausePredictionQuality}
      status={rootCausePredictionStatus}
      predictedTitle={translations.predicted}
      usedTitle={translations.used}
    />
  );
};

export default RootCausePredictionQuality;

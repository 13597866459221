import { Theme } from '@mui/material';
import assertNever from 'assert-never';
import { isDefined } from './isDefined';

export enum Trend {
  Positive = 'Positive',
  Negative = 'Negative',
  Neutral = 'Neutral',
}

export const getTrend = (trend?: number | null): Trend => {
  if (!isDefined(trend) || trend === 0) {
    return Trend.Neutral;
  }

  return trend > 0 ? Trend.Positive : Trend.Negative;
};

export const getTrendColor = (trend: Trend, theme: Theme): string => {
  switch (trend) {
    case Trend.Positive:
      return theme.palette.success.main;
    case Trend.Negative:
      return theme.palette.error.main;
    case Trend.Neutral:
      return '#C7C5BD'; // TODO: move this grey color to app theme
    default:
      assertNever(trend);
  }
};

export const getTrendArrow = (trend: Trend): string => {
  switch (trend) {
    case Trend.Positive:
      return '▲';
    case Trend.Negative:
      return '▼';
    case Trend.Neutral:
      return '--';
    default:
      assertNever(trend);
  }
};

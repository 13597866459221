import React from 'react';
import { Typography } from '@mui/material';

import { useTranslations } from '../../../../store/slices/translation.slice';
import { PredictionQualitySummary } from '../../../../integration/predictionQuality.api';
import styles from './PredictionQualityConfidences.module.scss';

interface Props {
  predictionQuality: PredictionQualitySummary;
}

const PredictionQualityConfidences: React.FC<Props> = ({
  predictionQuality,
}) => {
  const { predictionQuality: translations } = useTranslations();
  const { highConfidence, mediumConfidence, lowConfidence } = predictionQuality;

  const totalConfidence = highConfidence + lowConfidence + mediumConfidence;

  const highConfidencePercentage = (highConfidence / totalConfidence) * 100;
  const mediumConfidencePercentage = (mediumConfidence / totalConfidence) * 100;
  const lowConfidencePercentage = (lowConfidence / totalConfidence) * 100;

  return (
    <div>
      <div className={styles.confidencesSection}>
        <div className={styles.confidencesBar}>
          <div style={{ width: `${highConfidencePercentage}%` }} />
          <div style={{ width: `${mediumConfidencePercentage}%` }} />
          <div style={{ width: `${lowConfidencePercentage}%` }} />
        </div>
        <Typography variant="subtitle1" className={styles.confidencesLabel}>
          {predictionQuality.total} {translations.allPredictions}
        </Typography>
      </div>
      <div className={styles.confidencesLegends}>
        <Typography variant="subtitle1">
          {translations.highConfidence} ({predictionQuality.highConfidence})
        </Typography>
        <Typography variant="subtitle1">
          {translations.mediumConfidence} ({predictionQuality.mediumConfidence})
        </Typography>
        <Typography variant="subtitle1">
          {translations.lowConfidence} ({predictionQuality.lowConfidence})
        </Typography>
      </div>
    </div>
  );
};

export default PredictionQualityConfidences;

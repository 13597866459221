import React, { useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import { PredictionQuality } from '../../../../integration/predictionQuality.api';
import { useTranslations } from '../../../../store/slices/translation.slice';
import CorrectPredictionsChart from './correctPredictionsChart/CorrectPredictionsChart';
import { useDarkModeSelected } from '../../../../store/slices/theme.slice';
import styles from './PredictionQualitySample.module.scss';

interface Props {
  details: PredictionQuality[];
  predictedTitle: string;
  usedTitle: string;
}

const PredictionQualitySample: React.FC<Props> = ({
  details,
  predictedTitle,
  usedTitle,
}) => {
  const isDarkMode = useDarkModeSelected();
  const { predictionQuality: translations } = useTranslations();

  const wasLabelPredicted = useCallback(
    (label: string) => {
      return (
        details[0].predictedLabels.find((prLabel) => prLabel === label) !==
        undefined
      );
    },
    [details[0].predictedLabels],
  );

  const trueLabelCount = details[0].trueLabels.length;
  const correctPredictionCount = useMemo(() => {
    const correctPredictions = details[0].trueLabels.filter(wasLabelPredicted);

    return correctPredictions.length;
  }, [details, wasLabelPredicted]);
  const performance = Math.round(
    (correctPredictionCount / trueLabelCount) * 100,
  );

  return (
    <div className={clsx(styles.container, isDarkMode && styles.darkMode)}>
      <div>
        <div className={styles.jobDiagnosis}>
          <Typography variant="body1" className={styles.diagnosisTitle}>
            {translations.jobDiagnosis}
          </Typography>
          <Typography variant="body1">{details[0].jobDiagnosis}</Typography>
        </div>

        <div className={styles.labels}>
          <div>
            <Typography variant="body1">
              <b>{predictedTitle}</b>
            </Typography>
            <ul className={styles.predictions}>
              {details[0].predictedLabels.map((label) => (
                <li key={label}>{label}</li>
              ))}
            </ul>
          </div>
          <div>
            <Typography variant="body1">
              <b>{usedTitle}</b>
            </Typography>
            <ul className={styles.predictions}>
              {details[0].trueLabels.map((label, index) => (
                <li
                  key={index}
                  className={
                    wasLabelPredicted(label)
                      ? styles.predicted
                      : styles.notPredicted
                  }
                >
                  <span>{label}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div className={styles.performance}>{performance}%</div>
        <CorrectPredictionsChart
          correctPredictionCount={correctPredictionCount}
          trueLabelCount={trueLabelCount}
        />
      </div>
    </div>
  );
};

export default PredictionQualitySample;

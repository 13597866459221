import React, { useState } from 'react';
import { Tab, Tabs, useTheme } from '@mui/material';
import clsx from 'clsx';

import { ReactComponent as RootCausePrediction } from './assets/root-cause-prediction-quality.svg';
import { ReactComponent as PartPrediction } from './assets/part-prediction-quality.svg';
import RootCausePredictionQuality from './RootCausePredictionQuality';
import PartPredictionQuality from './PartPredictionQuality';
import { useTranslations } from '../../store/slices/translation.slice';
import styles from './PredictionQuality.module.scss';
import Feature from '../feature/Feature';

enum ActiveTab {
  RootCausePrediction,
  PartPrediction,
}

const PredictionQuality: React.FC = () => {
  const theme = useTheme();
  const { predictionQuality: translations } = useTranslations();
  const [activeTab, setActiveTab] = useState<ActiveTab>(
    ActiveTab.RootCausePrediction,
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant="fullWidth"
        className={styles.tabs}
        TabIndicatorProps={{ style: { background: theme.palette.info.main } }}
      >
        <Tab
          icon={<RootCausePrediction />}
          iconPosition="start"
          label={translations.rootCauses.title}
        />
        <Tab
          icon={<PartPrediction />}
          iconPosition="start"
          label={translations.parts.title}
        />
      </Tabs>
      <div className={styles.content}>
        <div
          className={clsx(
            activeTab === ActiveTab.RootCausePrediction && styles.active,
          )}
        >
          <Feature className={styles.container}>
            <RootCausePredictionQuality />
          </Feature>
        </div>
        <div
          className={clsx(
            activeTab === ActiveTab.PartPrediction && styles.active,
          )}
        >
          <Feature className={styles.container}>
            <PartPredictionQuality />
          </Feature>
        </div>
      </div>
    </>
  );
};

export default PredictionQuality;
